<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center pb-4 px-3>
      <v-flex xs12 text-left>
        <span  style="font-family: poppinsbold;
                  font-size: 20px;"> Edit Event </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs12 md12>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  class="textField2"
                  dense
                  type="text"
                  v-model="product.name"
                  outlined
                  label="Product Name"
                  required
                ></v-text-field>
              </v-flex>
               <v-flex xs12 pt-5>
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="product.fromDate"
                          label="From Date"
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="product.fromDate"
                        no-title
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs12 pt-5>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="product.toDate"
                          label="To Date"
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="product.toDate"
                        no-title
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
              <v-flex xs12 md12 text-left>
                 <span class="textField1"
                  >Event Description</span
                >
                <div id="app">
                  <vue-editor v-model="product.shortDescription"></vue-editor>
                </div>
              </v-flex>
              <v-flex xs12 md12 text-left pb-4>
                <span class="textField1"
                  >Event Detailed Description</span
                >
                <div id="app">
                  <vue-editor v-model="product.description"></vue-editor>
                </div>
              </v-flex>
            <v-flex xs12>
                    <v-text-field
                      class="homeText"
                      outlined
                      dense
                      v-model="product.venue"
                      label="venue"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      class="homeText"
                      outlined
                      type="number"
                      dense
                      v-model="product.contactNumber"
                      label="Contact Number"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      class="homeText"
                      outlined
                      type="email"
                      dense
                      v-model="product.contactEmail"
                      label="Contact E-mail"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      class="homeText"
                      outlined
                      dense
                      type="text"
                      v-model="product.contactAddress"
                      label="Contact Address"
                    ></v-text-field>
                  </v-flex>
            </v-layout>
          </v-flex>
         <!-- <v-flex md12 xs12>
            <ImageComp
              @stepper="winStepper"
              :photos="details.bannerImage"
              :pageId="details._id"
              :height="'800'"
              :width="'600'"
              :type="'banner'"
              :heading="'Upload Banner Images'"
            />
          </v-flex>
          <v-flex xs12 pt-5>
            <ImageComp
              @stepper="winStepper"
              :photos="details.eventImage"
              :pageId="details._id"
              :height="'800'"
              :width="'600'"
              :type="'event'"
              :heading="'Upload Event Images'"
            />
          </v-flex>
           <v-flex xs12 pt-5 >
            <ImageComp 
              @stepper="winStepper"
              :photos="details.gallery"
              :pageId="details._id"
              :height="'800'"
              :width="'600'"
              :type="'gallery'"
              :heading="'Upload Gallery Images'"
            />
          </v-flex> -->
        <!-----------------------docs edit------------------------->
         <v-flex xs12 pt-6>
                    <v-icon x-large color="#408BFF" @click="$refs.files.click()"
                      >mdi-file-upload</v-icon
                    >
                  </v-flex>
                  <v-flex xs12 pb-5>
                    <span class="con4">upload Document</span>
                    &nbsp;
                    <input
                      v-show="false"
                      id="file1"
                      ref="files"
                      type="file"
                      multiple
                      @change="browseCover"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                     <!-- doc from backend -->
                       <v-flex
                    xs2
                    py-3
                    text-left
                    v-for="(item, i) in documentFile2"
                    :key="i"
                  >
                    <!-- <v-chips> -->
                    <v-chip
                      close
                       @click:close="(delimgdialoge = true), (indexid = i)"
                      
                      dark
                      color="#005f32"
                      style="margin-right: 6px;margin-b"
                    >
                      {{ item }}
                    </v-chip>
                    <!-- </v-chips> -->
                  </v-flex>
                    </v-layout>
                  </v-flex>
                  <!-- doc uploaded -->
                    <v-flex xs12 v-if="documentFile1">
                    <v-layout wrap v-if="documentFile1.length>0">    
                  <v-flex
                    xs2
                    py-3
                    text-left
                    v-for="(item, i) in documentFile1"
                    :key="i"
                  >
                    <!-- <v-chips> -->
                    <v-chip
                      @click="documentFile1"
                      close
                       @click:close="documentFile1.splice(i,1)"
                      dark
                      color="#005f32"
                      style="margin-right: 6px;margin-b"
                    >
                      {{ item.name }}
                    </v-chip>
                    <!-- </v-chips> -->
                  </v-flex>
                    </v-layout>
                    </v-flex>
        <!--------------------------------------------------------->
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  color="#005f32"
                  light
                  :ripple="false"
                  depressed
                  @click="validateInput()"
                  class="itemValue"
                >
                  <span style="color: #ffffff">Save</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
          <v-dialog width="400px" v-model="delimgdialoge">
            <v-card width="400px">
              <v-toolbar dark color="#005f32" dense flat class="body-2">
                <v-toolbar-title> Confirm Delete</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-4 black--text"
                >Are you sure you want to delete this event?
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="delimgdialoge = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="deleteEvent()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
// import ImageComp from "@/components/Common/multipleImages2";
export default {
  components: {
    // ImageComp,
      VueEditor,
  },
  props: ["id"],
  data() {
    return {
      product: {
        name: null,
        description: null,
        units: [],
        speciality:null,
        totalQuantity:null,
        price:null,
        weight: null,
        unitType: null,
        makebanner:false,
        bannertext:null,
      },
      indexid:null,
      delimgdialoge:false,
      valid: false,
      imageArray: [],
      details:[],
      formData: new FormData(),
      selectedFiles2: [],
      image: null,
      formDataCover: new FormData(),
      date: new Date().toISOString().substr(0, 10),
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      coverImageFile: null,
      productStock: {},
      imageFiles: [],
       //upload docs
      docsdata: new FormData(),
      selectedFiles: null,
      documentFile1: [],
      menu1: false,
      menu2: false,
      documentFile2:[],

    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
       if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles2);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
      } else if (window_data.type == "banner") {
        this.coverImageFile = window_data.selectedFiles2;
      }
      else if (window_data.productStock) {
        this.productStock = window_data.productStock;
        this.product.units = this.productStock;
        // if(this.winStepper.type=="edit")
        // this.getData();
      }
      
      // else if (window_data.unitUpdate) {
      //   this.productStock = window_data.unitUpdate;
      //   this.product.units=this.productStock
      // }
      else if (window_data.type == "imageDeletion")
      {
      this.getData();
    }
    },
    validateInput() {
      if (!this.product.name) {
        this.msg = "Please Provide Product name";
        this.showSnackBar = true;
        return;
      } else if (!this.product.fromDate) {
        this.msg = "Please Provide From Date";
        this.showSnackBar = true;
        return;
      }else if (!this.product.toDate) {
        this.msg = "Please Provide To Date";
        this.showSnackBar = true;
        return;
      }else if (!this.product.shortDescription) {
        this.msg = "Please Provide Short Description";
        this.showSnackBar = true;
        return;
      } else if (!this.product.description) {
        this.msg = "Please Provide Description";
        this.showSnackBar = true;
        return;
      }  else if (!this.product.venue) {
        this.msg = "Please Provide Venue";
        this.showSnackBar = true;
        return;
      }else if (!this.product.contactNumber) {
        this.msg = "Please Provide Contact Number";
        this.showSnackBar = true;
        return;
      }else if (!this.product.contactEmail) {
        this.msg = "Please Provide Email";
        this.showSnackBar = true;
        return;
      }else if (!this.product.contactAddress) {
        this.msg = "Please Address";
        this.showSnackBar = true;
        return;
      }
      //  else if (!this.imageFiles.length >1 && this.details.allPhotos.length>0) {
      //   this.msg = "Please Upload Image";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.coverImageFile && !this.details.coverImage) {
      //   this.msg = "Please Upload Banner Image";
      //   this.showSnackBar = true;
      //   return;
      // }
      // else if (!this.product.unitType) {
      //   this.msg = "Please provide Unit Type";
      //   this.showSnackBar = true;
      //   return;
      // } else if (this.product.units.length <= 0) {
      //   if (!this.product.totalQuantity) {
      //     this.msg = "Please provide Total Quantity";
      //     this.showSnackBar = true;
      //     return;
      //   }
      //   if (!this.product.price) {
      //     this.msg = "Please provide Price";
      //     this.showSnackBar = true;
      //     return;
      //   }
      //   if (!this.product.weight) {
      //     this.msg = "Please provide Weight";
      //     this.showSnackBar = true;
      //     return;
      //   } else {
      //     this.eventEdit();
      //   }
      // }
      
      
      else {
        this.eventEdit();
      }
    },
     getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/media/admin/event/view",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id:this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.product = response.data.data;
            this.details = this.product;
            this.documentFile2 = response.data.data.pressKit;
            // this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;

          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    remove(item) {
      this.product.speciality.splice(
        this.product.speciality.indexOf(item),
        1
      );
      this.product.speciality = [...this.product.speciality];
    },
    editProduct() {
         this.product.id = this.id;
      axios({
        method: "post",
        url: "/product/edit",
        data: this.product,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            this.showSnackBar=true;
              if (this.documentFile1.length > 0) {
                console.log("docfile=",this.documentFile1);
                this.documentID = response.data.id;
                console.log("documentID", this.documentID);
                console.log("documentfile", this.documentFile1);
                if (this.documentID) {
                  this.docsupload();
                } else {
                  alert("document updated");
                }
              }
                else{
        this.getData();
                }
            this.msg = response.data.msg;
            this.showSnackBar = true;

          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
     browseCover(event) {
      var got = event.target.files;
      if (got.length < 5) {
        for (let i = 0; i < got.length; i++) {
          this.documentFile1.push(got[i]);
        }
        console.log("file isss", this.documentFile1);
      } else {
        this.msg = "Maximum 5 can be uploaded";
        this.showSnackBar = true;
      }
    },
deleteEvent() {
      axios({
        url: "/event/removeDocument",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id:this.$route.query.id,
          position:this.indexid
        },
      })
        .then((response) => {
          this.delete = false;
          this.delimgdialoge = false;
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            // this.$router.go(-1)
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addDocument() {
      if (this.documentFile1.length > 0) {
        this.appLoading = true;
        axios({
          url: "/policy/add",
          method: "post",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            name: this.title,
            id:this.$route.query.id,
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.documentID = response.data.data._id;
            console.log("documentID", this.documentID);
            console.log("documentfile", this.documentFile1);
            if (this.documentID) {
              this.docsupload();
            } else {
              alert("document updated");
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      } else {
        this.msg = "Please Select Document ";
        this.showSnackBar = true;

      }
    },
    docsupload() {
      for (var i = 0; i < this.documentFile1.length; i++) {
        this.docsdata.append("pressKit", this.documentFile1[i]);
      }
      this.docsdata.append("id", this.documentID);

      this.appLoading = true;
      axios({
        url: "/media/upload/event/document",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.docsdata,
      })
        .then((response) => {
          this.appLoading = false;
          this.dialog = false;
          this.documentFile1 = [];
          this.docsdata = new FormData();
          (this.title = null);
          this.getData();
          this.msg = response.data.msg;
          this.showSnackBar = true;
          window.location.reload();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },  
     eventEdit() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/media/event/edit",
        data: {
          name: this.product.name,
          fromDate: this.product.fromDate,
          toDate: this.product.toDate,
          shortDescription: this.product.shortDescription,
          description: this.product.description,
          venue: this.product.venue,
          contactNumber: this.product.contactNumber,
          contactEmail: this.product.contactEmail,
          contactAddress: this.product.contactAddress,
           id:this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          console.log("list=", response.data.data);
          if (response.data.status == true) {
            if (response.data.id) {
              console.log("id=", response.data.id);
              if (this.documentFile1.length > 0) {
                console.log("hiii");
                this.documentID = response.data.id;
                console.log("documentID", this.documentID);
                console.log("documentfile", this.documentFile1);
                if (this.documentID) {
                  this.docsupload();
                } else {
                  alert("document updated");
                }
              }
              if (this.imageArray.length > 0) {
                console.log("imgarrray=", this.imageArray);
                this.uploadAllImages(response.data.id);
                this.msg = response.data.msg;
                this.showSnackBar = true;
              }
              if (this.bannerimageArray.length > 0) {
                console.log("bannerimageArray=", this.bannerimageArray);
                this.uploadAllImages2(response.data.id);
                this.msg = response.data.msg;
                this.showSnackBar = true;
              }
               if (this.galleryimageArray.length > 0) {
                console.log("galleryimageArray=", this.galleryimageArray);
                this.uploadAllImages3(response.data.id);
                this.msg = response.data.msg;
                this.showSnackBar = true;
              }
            }

            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })

        .catch((err) => {
          this.appLoading = false;

          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}
.v-application .primary {
  background-color: red !important;
}
</style>